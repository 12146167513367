import React from 'react'
import Layout from 'components/layout/Layout'
import Header from 'components/header/Header'
import Main from 'components/layout/Main'

const NotFoundPage: React.FunctionComponent = () => (
  <Layout>
    <Header text="О себе" />
    <Main>
      <p>
        Страница не найдена. Попробуйте спросить о её существовании{' '}
        <a href="/contact">в форме обратной связи</a>.
      </p>
    </Main>
  </Layout>
)

export default NotFoundPage
